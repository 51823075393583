export const team = [
    {name:"Cameroun",flag:"https://countryflagsapi.com/svg/cm",},
    {name:"Ghana",flag:"https://countryflagsapi.com/svg/gh",},
    {name:"Maroc",flag:"https://countryflagsapi.com/svg/ma",},
    {name:"Sénégal",flag:"https://countryflagsapi.com/svg/sn",},
    {name:"Tunisie",flag:"https://countryflagsapi.com/svg/tn",},
    {name:"Argentine",flag:"https://countryflagsapi.com/svg/ar",},
    {name:"Brésil",flag:"https://countryflagsapi.com/svg/br",},
    {name:"Equateur",flag:"https://countryflagsapi.com/svg/ec",},
    {name:"Uruguay",flag:"https://countryflagsapi.com/svg/uy",},
    {name:"Canada",flag:"https://countryflagsapi.com/svg/ca",},
    {name:"Etats-Unis",flag:"https://countryflagsapi.com/svg/us",},
    {name:"Mexique",flag:"https://countryflagsapi.com/svg/mx",},
    {name:"Costa Rica",flag:"https://countryflagsapi.com/svg/cr",},
    {name:"Quatar",flag:"https://countryflagsapi.com/svg/qa",},
    {name:"Arabie Saoudite",flag:"https://countryflagsapi.com/svg/sa",},
    {name:"Australie",flag:"https://countryflagsapi.com/svg/au",},
    {name:"Corée du Sud",flag:"https://countryflagsapi.com/svg/kr",},
    {name:"Iran",flag:"https://countryflagsapi.com/svg/ir",},
    {name:"Japon",flag:"https://countryflagsapi.com/svg/jp",},
    {name:"Angleterre",flag:"https://countryflagsapi.com/svg/gb-eng",},
    {name:"Allemagne",flag:"https://countryflagsapi.com/svg/de",},
    {name:"Belgique",flag:"https://countryflagsapi.com/svg/be",},
    {name:"Croatie",flag:"https://countryflagsapi.com/svg/hr",},
    {name:"Danemark",flag:"https://countryflagsapi.com/svg/dk",},
    {name:"Espagne",flag:"https://countryflagsapi.com/svg/es",},
    {name:"France",flag:"https://countryflagsapi.com/svg/fr",},
    {name:"Pays-Bas",flag:"https://countryflagsapi.com/svg/nl",},
    {name:"Pays de Galles",flag:"https://countryflagsapi.com/svg/gb-wls",},
    {name:"Portugal",flag:"https://countryflagsapi.com/svg/pt",},
    {name:"Pologne",flag:"https://countryflagsapi.com/svg/pl",},
    {name:"Serbie",flag:"https://countryflagsapi.com/svg/rs",},
    {name:"Suisse",flag:"https://countryflagsapi.com/svg/ch",},
]